import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import GoogleReviews from './GoogleReviews';

const AllGoogleReviews = (props) => {
  const data = useStaticQuery(graphql`
    query {
        allBranches(
            filter: {brand_id: {eq: "wk"}, publish: {eq: true}}
            sort: {fields: branch_name, order: ASC}
          ) {
            edges {
              node {
                branch_name
                branch_id
                brand_id
                slug
                _id
                
              }
            }
          }
    }
  `);

  const allbranches = data.allBranches.edges; 
  let branchList = [];
  if (allbranches.length > 0) {
    allbranches.map(({ node }, i) => {
      branchList.push({ value: node.branch_name, label: node.branch_name })

    })
  }

  return (
    <>
      {allbranches && allbranches.length > 0 &&
        <GoogleReviews branch_name={branchList[0].value} allbranches={branchList} isBranchDetailPage={false} />
      }
    </>
  )
}
export default AllGoogleReviews
